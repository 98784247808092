import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enable-submit-button-if-fields-changed"
export default class extends Controller {
    connect() {
        this.element.querySelector('button[type=submit]:not([name=back])').disabled = true;
    }

    enableSubmit() {
        this.element.querySelector('button[type=submit]:not([name=back])').disabled = false;
    }
}