import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["topics"];

    toggle(event) {
        const index = event.currentTarget.dataset.index;
        this.topicsTargets.forEach((topics) => {
            if (topics.dataset.index === index) {
                topics.classList.toggle("hidden");
            }
        });
    }
}