import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['start', 'end', 'firstReminder', 'secondReminder', 'thirdReminder']

    startDateChanged() {
        const startDate = new Date(this.startTarget.value);
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + 14);
        this.updateDate(this.endTarget, startDate, 14);
        this.updateDate(this.firstReminderTarget, startDate, 7);
        this.updateDate(this.secondReminderTarget, startDate, 11);
        this.updateDate(this.thirdReminderTarget, startDate, 13);
    }

    updateDate(target, startDate, daysToAdd) {
        const date = new Date(startDate);
        // The html datetime format is "datetime-local"
        // When we create a new Date object, it will be created in the local timezone
        // however, when doing the to_iso_string, it will be converted to UTC, so we add the offset back
        const timeZoneOffsetInHours = startDate.getTimezoneOffset() / 60;

        date.setDate(startDate.getDate() + daysToAdd);
        date.setHours(date.getHours() - timeZoneOffsetInHours);

        //Set the dateTime in the correspondent input field
        target.value = date.toISOString().slice(0, 16);
    }
}