// todo_assignee_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["assigneeField"]

    updateAssignee(event) {
        const assigneeId = event.currentTarget.dataset.memberId;
        this.assigneeFieldTarget.value = assigneeId;
        this.assigneeFieldTarget.form.requestSubmit();
    }
}