import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["hiddenDiv"]

    toggle() {
        this.hiddenDivTargets.forEach(
            target => {
                if (target.classList.contains("hidden")) {
                    target.classList.remove("hidden");
                } else {
                    target.classList.add("hidden");
                }
            });
    }
}