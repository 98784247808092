/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"
import "flowbite/dist/flowbite.turbo.js";

import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

import { initFlowbite } from "flowbite";

addEventListener("turbo:before-stream-render", (event) => {
    const originalRender = event.detail.render

    event.detail.render = function (streamElement) {
        originalRender(streamElement)
        initFlowbite();
    }
})